import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Billboard from '../billboard';
import Gateway from '../gateway';
import ImageGrid from '../imageGrid';
import JobInfo from '../jobinfo';
import JobsListing from '../jobs/listing';
import Photos from '../photos';
import PostGrid from '../post/grid';
import Quote from '../quote';
import TeamGrid from '../teamGrid';
import Text from '../text';

import styles from './renderer.module.scss';

class SectionsRenderer extends Component {
  render() {
    const { sections } = this.props;

    if (!sections || sections.length === 0) { return null; }

    const renderedSections = sections.map((section, index) => {
      const key = section.id + index;

      switch (section.__typename) {
        case 'ContentfulBillboard':
          return <Billboard key={key} {...section} copy={section.copy && section.copy.copy} smallSize={!!section.smallSize}/>;
        case 'ContentfulGateway':
          return <Gateway key={key} {...section} descriptionLeft={section.descriptionLeft && section.descriptionLeft.descriptionLeft} descriptionRight={section.descriptionRight && section.descriptionRight.descriptionRight}/>
        case 'ContentfulImageGrid':
          return <ImageGrid key={key} headline={section.headline} images={section.images}/>
        case 'ContentfulJob':
          return <JobInfo key={key} {...section} description={section.description && section.description.description}/>
        case 'ContentfulJobListing':
          return <JobsListing key={key} {...section} description={section.description && section.description.description}/>
        case 'ContentfulPhotos':
          return <Photos key={key} {...section}/>
        case 'ContentfulPostGrid':
          return null; //<PostGrid key={key} {...section}/>
        case 'ContentfulQuote':
          return <Quote key={key} quote={section.quote && section.quote.quote} author={section.author}/>;
        case 'ContentfulTeamGrid':
          return <TeamGrid key={key} {...section}/>
        case 'ContentfulText':
          return <Text key={key} text={section.text && section.text.text} alignment={section.alignment ? section.alignment : undefined} lead={section.lead} teaser={section.teaser}/>;
        default:
          return null;
      }
    });

    return (
      <div className={styles.renderer}>
        {renderedSections}
      </div>
    );
  }
}

SectionsRenderer.propTypes = {
  sections: PropTypes.array.isRequired
}

SectionsRenderer.defaultProps = {
  sections: []
}

export default SectionsRenderer;
