import React from "react";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Container from '../container';
import Headline from '../headline';
import TextRenderer from '../text/renderer';

import styles from './index.module.scss';

const Billboard = ({
  headline="Please add a headline",
  copy='Please add a copy text.',
  visualMobile, visualMedium, visualLarge,
  visualMobileSmall, visualMediumSmall, visualLargeSmall,
  smallSize=false
}) => {
  const mobile = smallSize ? visualMobileSmall : visualMobile;
  const medium = smallSize ? visualMediumSmall : visualMedium;
  const large = smallSize ? visualLargeSmall : visualLarge;

  return (
    <div className={styles.billboard} data-small={smallSize}>
      {( mobile &&
        <div className={styles.background}>
          <div className={styles.gradient}/>
          <Img
            fluid={[
              mobile.fluid,
              {
                ...medium.fluid,
                media: `(min-width: 768px) and (max-width: 991px)`,
              },
              {
                ...large.fluid,
                media: `(min-width: 992px)`,
              },
            ]}
            alt={visualMobile.title}
            placeholderStyle={{ filter: 'blur(10px)' }}
          />
        </div>
      )}
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.text}>
            <div className={styles.headline}>
              <Headline headline={headline}/>
            </div>
            <div className={styles.copy}>
              <TextRenderer text={copy}/>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
};

export default Billboard;

export const query = graphql`
  fragment Billboard on ContentfulBillboard {
    __typename
    id
    headline
    copy {
      copy
    }
    visualMobile: visual {
      title
      fluid(maxWidth: 720, maxHeight: 460) {
        ...GatsbyContentfulFluid_withWebp
      }
    }

    visualMedium: visual {
      fluid(maxWidth: 770, maxHeight: 770, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }

    visualLarge: visual {
      fluid(maxWidth: 1200, maxHeight: 800, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }

    visualMobileSmall: visual {
      fluid(maxWidth: 720, maxHeight: 460) {
        ...GatsbyContentfulFluid_withWebp
      }
    }

    visualMediumSmall: visual {
      fluid(maxWidth: 770, maxHeight: 640, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }

    visualLargeSmall: visual {
      fluid(maxWidth: 1200, maxHeight: 600, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    smallSize
  }
`;
