import React from "react";
import { graphql } from 'gatsby';

import Container from '../container';
import Headline from '../headline';

import styles from './index.module.scss';

const ImageGrid = ({ headline="Please add a headline", images=[] }) => {
  const items = [...images, ...images, ...images, ...images, ...images, ...images, ...images].map((image, index) => (
    <div key={image.id + index} className={styles.image}>
      <img src={image && image.file && image.file.url} alt=""/>
    </div>
  ))

  return (
    <div className={styles.wrapper} data-background="light">
      <Container>
        <div className={styles.headline}>
          <Headline headline={headline}/>
        </div>
        <div className={styles.images}>
          {items}
        </div>
      </Container>
    </div>
  )
};

export default ImageGrid;

export const query = graphql`
  fragment ImageGrid on ContentfulImageGrid {
    __typename
    id
    headline
    images {
      ...Image
    }
  }
`;
