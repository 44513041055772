import React from "react";
import { graphql } from 'gatsby';

import Container from '../container';
import Headline from '../headline';
import PostGridItem from './item';

import styles from './grid.module.scss';

const PostGrid = ({ title="Please add a title", posts=[] }) => {
  const items = [...posts].map((post, index) => <PostGridItem key={post.id + index} post={post} teaser={post.teaser && post.teaser.teaser}/>)

  return (
    <section className={styles.grid}>
      <Container>
        <Headline headline={title}/>
        <div className={styles.posts}>{items}</div>
      </Container>
    </section>
  )
};

export default PostGrid;

export const query = graphql`
  fragment PostGrid on ContentfulPostGrid {
    __typename
    id
    title
  }
`;

// posts {
//   ...PostGridItem
// }
