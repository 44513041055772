import React from 'react';
import { graphql } from 'gatsby';

import Container from '../container';
import TextRenderer from './renderer';

import styles from './index.module.scss';

const Text = ({ text='Please add a text', alignment='Left', lead=false, teaser=false }) => (
  <section className={styles.text} data-alignment={alignment.toLowerCase()} data-lead={lead} data-teaser={teaser}>
    <Container>
      <div className={styles.content}>
        <TextRenderer text={text}/>
      </div>
    </Container>
  </section>
)

export default Text;

export const query = graphql`
  fragment Text on ContentfulText {
    __typename
    id
    text {
      text
    }
    alignment
    lead
    teaser
  }
`;
