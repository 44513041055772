import React from "react";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Container from '../container';
import Image from '../image';

import styles from './index.module.scss';

const Photos = ({ photos=[], showCaption=false }) => {
  if (photos.length === 0) return null;

  const items = [...photos].map(photo => (
    <div key={photo.id} className={styles.photo}>
      <Img fluid={photo.fluid}/>
    </div>
  ))

  return (
    <div className={styles.quote} data-background="light">
      <Container>
        <div className={styles.photos}>{items}</div>
      </Container>
    </div>
  )
};

export default Photos;

export const query = graphql`
  fragment Photos on ContentfulPhotos {
    __typename
    id
    photos {
      title
      fluid(maxWidth: 1110, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    showCaption
  }
`;
