import React from "react";
import { graphql } from 'gatsby';

import Container from '../container';
import TextRenderer from '../text/renderer';

import styles from './index.module.scss';

const Quote = ({ quote="Please add a quote text", author=false }) => (
  <div className={styles.quote} data-background="light">
    <Container>
      <div className={styles.textWrapper}>
        <div className={styles.text}>
          <TextRenderer text={quote}/>
        </div>
      </div>
      {( author &&
        <div className={styles.author}>{author}</div>
      )}
    </Container>
  </div>
);

export default Quote;

export const query = graphql`
  fragment Quote on ContentfulQuote {
    __typename
    id
    quote {
      quote
    }
    author
  }
`;
