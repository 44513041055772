import React from "react";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Container from '../container';
import TextRenderer from '../text/renderer';

import styles from './index.module.scss';

const TeamGrid = ({ persons=[] }) => {
  if (persons.length === 0) return null;

  const items = persons.map(person => <TeamGridPerson
      key={person.id}
      longName={person.longName}
      jobTitle={person.jobTitle}
      image={person.image}
      biography={person.biography}
    />
  );

  return (
    <div className={styles.grid}>
      <Container>
        <div className={styles.persons}>
          {items}
        </div>
      </Container>
    </div>
  )
}

const TeamGridPerson = ({
  longName='Please add the long name',
  jobTitle='Please add a job title',
  biography: { biography },
  image
}) => (
    <div className={styles.person}>
      {( image &&
        <div className={styles.image}>
          <Img fluid={image.fluid} alt={longName}/>
        </div>
      )}
      <div className={styles.name}>{longName}</div>
      <div className={styles.jobTitle}>{jobTitle}</div>
      {( biography &&
        <div className={styles.biography}>
          <TextRenderer text={biography}/>
        </div>
      )}
    </div>
  )

export default TeamGrid;

export const query = graphql`
  fragment TeamGrid on ContentfulTeamGrid {
    __typename
    id
    title
    persons {
      longName
      jobTitle
      biography {
        biography
      }
      image {
        fluid(maxWidth: 350, maxHeight: 270, quality: 75) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
