import React from 'react';
import { Link, graphql } from 'gatsby';

import Container from '../container';
import Headline from '../headline';
import TextRenderer from '../text/renderer';
import { pathTo } from '../../routes';

import styles from './listing.module.scss';

const JobsListing = ({ title, description, jobs=[] }) => (
  <div className={styles.listing}>
    <Container>
      <Headline headline={title}/>
      {( description &&
        <div className={styles.description}>
          <TextRenderer text={description}/>
        </div>
      )}
      {(jobs.length > 0 &&
        <ul className={styles.jobs}>
          {jobs.map(job => (
            <li className={styles.job}>
              <Link to={pathTo(job)}>{job.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </Container>
  </div>
)

export default JobsListing;

export const query = graphql`
  fragment JobListing on ContentfulJobListing {
    __typename
    id
    title
    description {
      description
    }
    jobs {
      __typename
      id
      title
      slug
    }
  }
`;
