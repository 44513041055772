import React from "react";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import ButtonLink from '../button/link';
import Container from '../container';
import { pathTo } from '../../routes';
import TextRenderer from '../text/renderer';

import styles from './index.module.scss';

const Gateway = ({
  headlineLeft, descriptionLeft, labelLeft, linkLeft, imageLeft, headlineRight, descriptionRight, labelRight, linkRight, imageRight
}) => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.gateways}>
          <GatewayComponent
            headline={headlineLeft}
            description={descriptionLeft}
            label={labelLeft}
            link={linkLeft}
            image={imageLeft}
          />
          {( headlineRight && descriptionRight &&
            <GatewayComponent
              headline={headlineRight}
              description={descriptionRight}
              label={labelRight}
              link={linkRight}
              image={imageRight}
            />
          )}
        </div>
      </Container>
    </div>
  )
};

const GatewayComponent = ({ headline='Please add a headline', description, label, link, image }) => {
  return (
    <div className={styles.gateway}>
      <div className={styles.content}>
        <h2 className={styles.headline}>{headline}</h2>
        {( description &&
          <div className={styles.description}>
            <TextRenderer text={description}/>
          </div>
        )}
        {( label && link &&
          <div className={styles.link}>
            <ButtonLink to={link}>{label}</ButtonLink>
          </div>
        )}
      </div>
      <div className={styles.background}>
        <div className={styles.fill}/>
        <Img
          fluid={image.fluid}
          alt={headline}
        />
      </div>
    </div>
  )
}

export default Gateway;

export const query = graphql`
  fragment Gateway on ContentfulGateway {
    __typename
    id
    headlineLeft
    descriptionLeft {
      descriptionLeft
    }
    labelLeft
    linkLeft
    imageLeft {
      fluid(maxWidth: 600, maxHeight: 340) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    headlineRight
    descriptionRight {
      descriptionRight
    }
    labelRight
    linkRight
    imageRight {
      fluid(maxWidth: 600, maxHeight: 340) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;
