import React from "react";
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { pathTo } from '../../routes';

import styles from './item.module.scss';

const PostGridItem = ({
  post,
  post: {
    title="Please add a post title",
    image
  },
  teaser="Please add a post teaser text.",
}) => {
  return (
    <Link to={pathTo(post)} className={styles.item}>
      {( image &&
        <div className={styles.image}>
          <Img fluid={image.fluid}/>
        </div>
      )}
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.teaser}>{teaser}</div>
      <div className={styles.more}><span>Weiterlesen</span><i className='fas fa-long-arrow-alt-right'/></div>
    </Link>
  )
};

export default PostGridItem;

export const query = graphql`
  fragment PostGridItem on ContentfulPost {
    __typename
    id
    title
    slug
    teaser {
      teaser
    }
    image: sharingImage {
      fluid(maxWidth: 340, maxHeight: 200, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;
