import React from "react";
import { graphql } from 'gatsby';

import Container from '../container';
import TextRenderer from '../text/renderer';

import styles from './index.module.scss';

const JobInfo = ({ title="Please add a job title", slug, description="Please add a detailed job description" }) => {
  return (
    <section id={slug} className={styles.job}>
      <Container>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.description}>
          <TextRenderer text={description}/>
        </div>
      </Container>
    </section>
  )
};

export default JobInfo;

export const query = graphql`
  fragment JobInfo on ContentfulJob {
    __typename
    id
    title
    slug
    description {
      description
    }
  }
`;
